<template>
  <div>
    <d-body-top-bar
      :title="$t('views.dashboard.title')">
      <div slot="tree">
        <label class="inactive-tree">
         {{ $t('views.dashboard.your-complex') }}
        </label>
        /
        <label class="bg-doinsport-primary ml-1">
          {{ $t('views.dashboard.your-dashboard') }}
        </label>
      </div>
    </d-body-top-bar>
    <content-top />
    <activities/>
    <stats/>
    <stats-club-playground/>
  </div>
</template>
<script>
export default {
  components: {
    ContentTop: () => import('./content/Top'),
    Activities: () => import('./content/activities/Index'),
    Stats: () => import('./content/stats/Index'),
    StatsClubPlayground: () => import('./content/stats/ClubPlayground')
  }
};
</script>

